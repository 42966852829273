<template>
  <v-app class="app">
    <router-view></router-view>
    <snackbar></snackbar>
  </v-app>
</template>

<script>
import Snackbar from "@/components/global/SnackbarComponent";

export default {
  name: "App",
  components: {
    Snackbar,
  },
  computed: {
  },
  methods: {},
  mounted() {

  },
};
</script>

<style lang="scss">
.app {
  .v-application__wrap {
    padding: 10px !important;
    background: #eef1ff !important;
  }
}
</style>
