import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "./plugins/axios";
import "./assets/style/_variables.scss";
import './assets/style/main.scss'
import 'vue-demi'; 
import clickOutside from './directives/click-outside';

// components
import BaseSelect from './components/base/SelectField'
import BaseInput from './components/base/InputField'
import BaseDate from './components/base/DateField'
import BasePassword from './components/base/PasswordField'
import BaseTextarea from './components/base/TextareaField'
// // audio
import { AVPlugin } from 'vue-audio-visual';

const app = createApp(App)

app.config.productionTip = false

app.component("base-select", BaseSelect);
app.component("base-input", BaseInput);
app.component("base-date", BaseDate);
app.component("base-password", BasePassword);
app.component("base-textarea", BaseTextarea);

app.provide("$axios", axios);

app.directive('click-outside', clickOutside);

app.config.globalProperties.$currentDate = () => {
    const current = new Date();
    const date = `${current.getFullYear()}`;
    return date;
};


// app.use(AudioVisual)
// app.use(AudioRecorder)

app.use(router).use(store).use(AVPlugin).use(axios).use(vuetify).mount("#app");
// "autoprefixer": "10.4.5",
// "wavesurfer.js": "^6.4.0",
// "wavesurfer.js-vue": "^1.0.0"