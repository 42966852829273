<template>
  <div class="custom-height base-input input-style">
    <v-text-field
      :type="type"
      variant="outlined"
      :placeholder="placeholder"
      dense
      :rules="rules"
      :hide-details="hideDetails"
      :readonly="readOnly"
      :append-icon="isSearch ? 'mdi-magnify' : ''"
      v-model="selected"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      validator: () => true,
    },
    type: {
      type: String,
      default: "text",
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || "this field is require"],
    },
    defaultSelected: {
      type: String,
      default: "",
    },
  },
  computed: {
    selected: {
      get() {
        return this.defaultSelected;
      },
      set(newVal) {
        if (this.selected !== newVal) {
          this.$emit("changeSelected", newVal);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.input-style {
  .v-input__slot {
    fieldset {
      border: 0px !important;
      background: #e5ebffab;
    }

    .v-input__append-inner {
      margin-top: 5px !important;
    }
    .v-text-field__slot {
      height: 35px !important;
    }
  }
  .text-error {
    .v-input__slot {
      fieldset {
        height: 40px !important;
        border: 1px solid #e44358 !important;
      }
    }
  }
}
</style>
