

import axios from 'axios'

const headers = {
    accesstoken: `${localStorage.getItem("token")}`
}

export const GetFileUsage = async (params) => {
    let res = await axios.get('files/user-system/usage', {
        params,
        headers
    })

    return res
}


export const GetFilesRecords = async (params, pageNumber = 1) => {
    let res = await axios.get('files/records/v2', {
        params: {
            ...params,
            pageNumber
        },
        headers
    })

    return res
}
export const GetFilesRecordsAnalysisMonth = async (params) => {
    let res = await axios.get('files/records/analysis/months', {
        params,
        headers
    })

    return res
}

export const GetFilesRecordsAnalysis = async (params) => {
   
    let res = await axios.get('files/records/files/analysis', {
        ...params,
        headers
    })

    return res
}

export const GetFilesRecordsAnalysisAgent = async (params) => {
    let res = await axios.get('files/records/analysis/agent', {
        ...params,
        headers
    })

    return res
}


export const GetFileRecords = async (id) => {
    let res = await axios.get(`files/records/${id}`, {
        headers
    })

    return res
}


export const CheckName = async (name) => {
    let res = await axios.get(`files/checkRecordName/${name}`, {
        headers
    })

    return res
}

export const FileMetaData = async (name) => {
    let res = await axios.get(`files/records/fileMetaData/${name}`, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "content-type": "audio/mp3",
        },
    })

    return res
}

export const FileUpdate = async (id, obj) => {
    let res = await axios.put(`files/update/${id}`, obj, {
        headers
    })

    return res
}

export const UpdateSpeakers = async (id, obj) => {
    let res = await axios.put(`files/speakers/${id}`, obj, {
        headers
    })

    return res
}
