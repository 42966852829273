// import axios from "@/plugins/axios";
// import store from "@/store";
import { GetPorfileDetails } from "@/services/Auth";
import store from "@/store";
import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/auth/",
    name: "Auth",
    meta: { requiresNoAuth: true, title: "Auth" },
    component: () => import("../views/auth/IndexView.vue"),
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("../views/auth/login/IndexView.vue"),
        meta: { requiresNoAuth: true, title: "Login" },
      },
      {
        path: "request-demo",
        name: "Request Demo",
        component: () => import("../views/auth/request-demo/IndexView.vue"),
        meta: { requiresNoAuth: true, title: "Request-demo" },
      },
      {
        path: "forget-password",
        name: "Forget-password",
        component: () => import("../views/auth/forget-password/IndexView.vue"),
        meta: { requiresNoAuth: true, title: "Forget-password" },
      },
      {
        path: "reset-password/:token",
        name: "Reset-password",
        component: () => import("../views/auth/reset-password/IndexView.vue"),
        meta: { requiresNoAuth: true, title: "Reset-password" },
      },
      {
        path: "set-password/:token",
        name: "set-password",
        component: () => import("../views/auth/set-password/IndexView.vue"),
        meta: { requiresNoAuth: true, title: "set-password" },
      },
      {
        path: "verify-code/:email",
        name: "Verify-Code",
        component: () => import("../views/auth/verify-code/IndexView.vue"),
        meta: { requiresNoAuth: true, title: "Verify-Code" },
      },
      {
        path: "review",
        name: "Review",
        component: () => import("../views/auth/review/IndexView.vue"),
        meta: { requiresNoAuth: true, title: "Review" },
      },
    ],
  },
  {
    path: "/privacy-main",
    name: "Privacy",
    component: () => import("../views/static-main/PrivacyView.vue"),
    meta: { requiresNoAuth: true, title: "Privacy" },
  },
  {
    path: "/terms-main",
    name: "Terms",
    component: () => import("../views/static-main/TermsView.vue"),
    meta: { requiresNoAuth: true, title: "Terms" },
  },
  {
    path: "/",
    meta: { requiresAuth: true },
    component: () => import("../layout/NavbarApp.vue"),
    children: [
      {
        path: "",
        name: "Homepage",
        component: () => import("../views/dashboard/homepage/IndexView.vue"),
        meta: { requiresAuth: true, title: "Homepage" },
      },
      {
        path: "/metrics",
        name: "Metrics",
        component: () => import("../views/dashboard/metrics/IndexView.vue"),
        meta: { requiresAuth: true, title: "Metrics" },
      },
      {
        path: "/requests",
        name: "Requests",
        component: () => import("../views/dashboard/requests/IndexView.vue"),
        meta: { requiresAuth: true, title: "Requests" },
      },
      {
        path: "/team_members",
        name: "Employees",
        component: () => import("../views/dashboard/employees/IndexView.vue"),
        meta: { requiresAuth: true, title: "Team Members" },
      },
      {
        path: "/support",
        name: "Support",
        component: () => import("../views/dashboard/support/IndexView.vue"),
        meta: { requiresAuth: true, title: "Support" },
      },
      {
        path: "/privacy",
        name: "Privacy-dashboard",
        component: () => import("../views/dashboard/static/PrivacyView.vue"),
        meta: { requiresAuth: true, title: "Privacy-dashboard" },
      },
      {
        path: "/terms-conditions",
        name: "Terms-dashboard",
        component: () => import("../views/dashboard/static/TermsView.vue"),
        meta: { requiresAuth: true, title: "Terms-dashboard" },
      },
      {
        path: "/users",
        name: "Users",
        component: () => import("../views/dashboard/users/IndexView.vue"),
        meta: { requiresAuth: true, title: "Users" },
      },
      {
        path: "/users/:id",
        name: "User",
        component: () => import("../views/dashboard/users/id/IndexView.vue"),
        meta: { requiresAuth: true, title: "Users" },
      },
      {
        path: "/account",
        name: "Account",
        component: () => import("../views/dashboard/account/IndexView.vue"),
        meta: { requiresAuth: true, title: "Account" },
      },
      {
        path: "/deepdive",
        name: "Deepdive",
        component: () => import("../views/dashboard/deepdive/IndexView.vue"),
        meta: { requiresAuth: true, title: "Deepdive" },
      },
      {
        path: "/record/:id",
        name: "Record",
        component: () => import("../views/dashboard/homepage/id/IndexView.vue"),
        meta: { requiresAuth: true, title: "Homepage" },
      },
    ]
  },




  //     {
  //       path: "/printer",
  //       name: "Printer",
  //       component: () => import("../views/dashboard/printer/IndexView.vue"),
  //       meta: { requiresAuth: true, title: "Printer" },
  //     },



  //   ],
  // },
  {
    path: "/blocked-user",
    name: "Blocked User",
    component: () => import("../views/blocked-user/IndexView.vue"),
    meta: { title: "Blocked User" },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  let token = localStorage.getItem("token");

  if (token) {
    if (!store.getters.userDetails?.fullName) {
      let res = await GetPorfileDetails();
      if (res.message == "User is blocked") {
        localStorage.removeItem("token");
        next({
          path: "/blocked-user",
        });
      }
      if (localStorage.getItem("token") && res.message == "Invalid token") {
        localStorage.removeItem("token");
        router.go()
      }else{
        store.dispatch("setUserDetails", res.data.data);
        store.commit("SET_USER", { token: token });
      }
    }
  }
  // check if logged
  let isLogin = store.getters.token;

  if (to.matched.some((record) => record.meta.requiresNoAuth)) {
    if (isLogin) {
      next({
        path: "/",
      });
    } else {
      next();
    }
    return;
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    // if not, redirect to login page.
    if (isLogin) {
      next();
    } else {
      next({
        path: "/auth/login",
      });
    }
    return;
  } else {
    next();
  }

  next();
});

export default router;
