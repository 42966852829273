import axios from 'axios'

export const ResetPassword = async (obj) => {
    let res = await axios.post('users/resetPassword', obj)
    return res
}

export const UserLogin = async (obj) => {
    let res = await axios.post('users/login', obj)
    return res
}

export const UserRegister = async (obj) => {
    let res = await axios.post('users/signUp', obj)
    return res
}

export const CreateDemoRequest = async (obj) => {
    let res = await axios.post('demo/request/create', obj)
    return res
}

export const UserUpdatePassword = async (obj, headers) => {
    let res = await axios.put('users/updatePassword', obj, {
        headers
    })
    return res
}

export const UserVerifyCode = async (obj) => {
    let res = await axios.post('users/verifyCode', obj)
    return res
}

export const GetPorfileDetails = async () => {
    let res = await axios.get('users/myProfile', {
        headers: {
            accesstoken: `${localStorage.getItem("token")}`,
        },
    })
    return res
} 