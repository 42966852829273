import store from "@/store";
import axios from "axios";

// import store from "../store";
axios.defaults.baseURL = "https://api.speakly.ca/";

let axiosApp = () => {
  // Show loader in each request
  axios.interceptors.request.use(
    (config) => {
      // store.dispatch('loading', true);
      return config;
    },
    (error) => {
      return error;
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      store.dispatch("showSnack", {
        text: error.response.data?.message || error.response.data?.message[0].message,
        color: "error",
      });
      // if (error.response?.data?.message == 'Invalid token') {
      //   store.dispatch("logout")
      // }
      // Response with status code > 200 is here
      return error.response.data;
    }
  );

};

export default axiosApp