<template>
  <div class="base-input base-select" :style="{ minWidth: width }">
    <v-select
      :items="items"
      :placeholder="placeholder"
      dense
      height="44px"
      max-height="44px"
      variant="outlined"
      background-color="lightbg"
      :hide-details="hideDetails"
      :item-value="itemValue"
      :item-text="itemText"
      :rules="rules"
      :multiple="multiple"
      :menu-props="{
        auto: true,
        contentClass: 'customClassName',
      }"
      clearable
      v-model="selected"
    >
      <template v-if="slotOptions">
        <div>item</div>
      </template>
      <template v-slot:prepend-item>
        <slot></slot>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: null,
    },
    itemValue: {
      type: String,
      default: "value",
    },
    itemText: {
      type: String,
      default: "title",
    },
    width: {
      type: String,
      default: "100px",
    },
    value: {
      validator: () => true,
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || ""],
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    slotOptions: {
      type: Boolean,
      default: false,
    },
    defaultSelected: {
      type: String,
      default: "",
    },
  },
  computed: {
    selected: {
      get() {
        return this.defaultSelected;
      },
      set(newVal) {
        if (this.selected !== newVal) {
          this.$emit("changeSelected", newVal);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.base-select {
  fieldset {
    border: 0px !important;
    background: rgb(122 149 238 / 42%);
  }
  .text-error {
    .v-input__slot {
      fieldset {
        height: 40px !important;
        border: 1px solid #e44358 !important;
      }
    }
  }
}
.customClassName {
  max-height: 400px !important;
}
</style>
