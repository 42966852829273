
// Vuetify
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";


// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";


const myCustomLightTheme = {
  dark: false,
  colors: {
    primary: "#3169E4",
    successGreen: '#4caf50',
    secondary: "#C8141E",
    "primary-1": "#08A7F9",
    "primary-2": "#2754B9",
    "lightprimary-1": "#C1EBFF",
    lightblue: "#00ABFF",
    red: "#ff6b64",
    lightprimary: "#C9DAFF",
    graybg: "#F0F5F8",
    lightbg: "#F9FAFF",
    greyText: "#787878",
    darkgreyText: "#393939",
    lightbluebg: "#EBF1FF",
    lightblackText: "#4E4E4E",
    darkgray: "#DCDCDC",
    black: "#222222",
    grey: "#7C7C7C",
    darkblack: "#000000",
    darkblue: "#111E4E",
    green: "#007A3D",
    halfwhite: "#ffffff96",
    lightred: "#FFDEDE",
    orange: "#FF5400",
    lightorange: "#FFAA80",
  },
}


export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  icons: {
    iconfont: "mdiSvg",
  },
  breakpoint: {
    scrollBarWidth: 24,
  },
  components,
  directives,
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme
    },
  },
});
